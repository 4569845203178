import { useCategoryContext } from '~/hooks/context/useCategoryContext';
import { useRouter } from 'next/router';
import { errorToMessage } from '~/libs/errorToMessage';
import { useToastMessages } from '~/hooks/context/useToastMessages';
import { isNotFoundError } from '~/utils/error/isNotFoundError';
import { useLogout } from './useLogout';
import { fixedPhrase } from '~/constants/fixedPhrase';

export type ErrorCallback = (error: unknown) => void;
export type DefaultErrorCallbacks<Keys extends string> = Partial<{ [K in Keys]: ErrorCallback }>;

export const defaultApiClientWithLogout = {
  logout: () => {
    throw fixedPhrase.unknownError;
  },
};

export const useErrorHandler = () => {
  const messageHook = useToastMessages();
  const router = useRouter();
  const categoryContext = useCategoryContext();

  const logoutHandler = useLogout(
    categoryContext.state?.apiClient || defaultApiClientWithLogout,
    router
  );

  return {
    handleError: (error: unknown, errorHandler?: ErrorCallback) => {
      if (errorHandler !== undefined) {
        errorHandler(error);
        return;
      }
      messageHook.actions.pushBundleDisplayedMessage(errorToMessage(error), {
        onClick: isNotFoundError(error) ? logoutHandler.actions.logout : undefined,
      });
    },
  };
};
