import axios, { AxiosInstance } from 'axios';
import { stringify } from 'querystring';
import { apiConfig } from '~/constants/apiConfig';
import { envManager } from '~/constants/envManager';

export const configuredAxiosClient = (): AxiosInstance => {
  const client = axios.create({
    baseURL: envManager.publicEnv.apiBaseUrl,
    withCredentials: true,
    // https://qiita.com/m_mitsuhide/items/7b638cf93dd086f27b8f
    paramsSerializer: (params) => stringify(params),
    headers: {
      [apiConfig.timeeApiCustomHeader]: apiConfig.timeeApiCustomHeader,
    },
  });

  return client;
};
