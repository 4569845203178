import { aspidaClient } from '~/apis/timeeApi/aspidaClient';
import { retriable } from '~/apis/timeeApi/retriable';
import { AccountSetupTokenInfo } from '~/categories/auth/models/types/accountSetupToken';

export type IAuthApiClient = {
  requestResetPassword: (params: { email: string }) => Promise<void>;
  login: (params: { email: string; password: string }) => Promise<void>;
  confirmAuthenticated: () => Promise<boolean>;
  setNewPassword: (paras: { password: string; accountSetupToken: string }) => Promise<void>;
  fetchAccountSetupTokenStatus: (params: {
    accountSetupToken: string;
  }) => Promise<AccountSetupTokenInfo>;
};

export const authApiClient: IAuthApiClient = {
  requestResetPassword: async (params) => {
    return retriable(() =>
      aspidaClient.api.v1.account_setup_tokens.$post({ body: { email: params.email } })
    );
  },
  login: async (params) => {
    return aspidaClient.api.v1.sign_in.$post({
      body: {
        email: params.email,
        password: params.password,
      },
    });
  },
  confirmAuthenticated: async () => {
    return (await aspidaClient.api.v1.sign_in.confirm.$get({})).result === 'ok';
  },
  setNewPassword: async (params) => {
    return retriable(() =>
      aspidaClient.api.v1.account_setup_tokens
        ._setup_token(params.accountSetupToken)
        .$patch({ body: { password: params.password } })
    );
  },
  fetchAccountSetupTokenStatus: async (params) => {
    return retriable(() =>
      aspidaClient.api.v1.account_setup_tokens._setup_token(params.accountSetupToken).$get({})
    );
  },
};
