/* eslint-disable */
import { AspidaClient, BasicHeaders, dataToURLString } from 'aspida'
import { Methods as Methods0 } from './api/v1/account'
import { Methods as Methods1 } from './api/v1/account_setup_tokens'
import { Methods as Methods2 } from './api/v1/account_setup_tokens/_setup_token@string'
import { Methods as Methods3 } from './api/v1/accounts/me'
import { Methods as Methods4 } from './api/v1/accounts/me/accessible_resources/clients'
import { Methods as Methods5 } from './api/v1/accounts/me/accessible_resources/companies'
import { Methods as Methods6 } from './api/v1/accounts/me/email_change_request'
import { Methods as Methods7 } from './api/v1/accounts/me/notification_counts'
import { Methods as Methods8 } from './api/v1/accounts/me/password'
import { Methods as Methods9 } from './api/v1/accounts/me/subscription/clients'
import { Methods as Methods10 } from './api/v1/clients/_client_id@number'
import { Methods as Methods11 } from './api/v1/clients/_client_id@number/attendance'
import { Methods as Methods12 } from './api/v1/clients/_client_id@number/attendances/fix_payment_requests'
import { Methods as Methods13 } from './api/v1/clients/_client_id@number/attendances/fix_payment_requests/_fix_payment_request_id@number'
import { Methods as Methods14 } from './api/v1/clients/_client_id@number/base'
import { Methods as Methods15 } from './api/v1/clients/_client_id@number/chat_rooms'
import { Methods as Methods16 } from './api/v1/clients/_client_id@number/chat_rooms/_chat_room_uid@string/messages'
import { Methods as Methods17 } from './api/v1/clients/_client_id@number/chat_rooms/_uid@string'
import { Methods as Methods18 } from './api/v1/clients/_client_id@number/chat_rooms/_uid@string/read'
import { Methods as Methods19 } from './api/v1/clients/_client_id@number/client_reviews'
import { Methods as Methods20 } from './api/v1/clients/_client_id@number/client_reviews/evaluation'
import { Methods as Methods21 } from './api/v1/clients/_client_id@number/company/np_buyer_alert'
import { Methods as Methods22 } from './api/v1/clients/_client_id@number/confirmed_billing_operations/_confirmed_billing_operation_id@number/detail.pdf'
import { Methods as Methods23 } from './api/v1/clients/_client_id@number/feedbacks'
import { Methods as Methods24 } from './api/v1/clients/_client_id@number/groups'
import { Methods as Methods25 } from './api/v1/clients/_client_id@number/groups/_group_id@number'
import { Methods as Methods26 } from './api/v1/clients/_client_id@number/groups/shared_group_summary'
import { Methods as Methods27 } from './api/v1/clients/_client_id@number/invitation'
import { Methods as Methods28 } from './api/v1/clients/_client_id@number/offering/multidate_posting_operations'
import { Methods as Methods29 } from './api/v1/clients/_client_id@number/offering/multidate_posting_operations/_multidate_posting_operation_id@number/check'
import { Methods as Methods30 } from './api/v1/clients/_client_id@number/offerings'
import { Methods as Methods31 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number'
import { Methods as Methods32 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/close'
import { Methods as Methods33 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/closed_reason'
import { Methods as Methods34 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/multidate_posting_operations'
import { Methods as Methods35 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/unpublished'
import { Methods as Methods36 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users'
import { Methods as Methods37 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users/_user_id@number/reject_request'
import { Methods as Methods38 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users/block_status'
import { Methods as Methods39 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users/unreviewed'
import { Methods as Methods40 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users.csv'
import { Methods as Methods41 } from './api/v1/clients/_client_id@number/offerings/calendar'
import { Methods as Methods42 } from './api/v1/clients/_client_id@number/offerings/unreviewed'
import { Methods as Methods43 } from './api/v1/clients/_client_id@number/offerings/wage'
import { Methods as Methods44 } from './api/v1/clients/_client_id@number/offers'
import { Methods as Methods45 } from './api/v1/clients/_client_id@number/offers/_offer_id@number'
import { Methods as Methods46 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/archive'
import { Methods as Methods47 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings'
import { Methods as Methods48 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings/_offering_id@number'
import { Methods as Methods49 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings/_offering_id@number/validate'
import { Methods as Methods50 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings/validate'
import { Methods as Methods51 } from './api/v1/clients/_client_id@number/offers/suggestion'
import { Methods as Methods52 } from './api/v1/clients/_client_id@number/phone_number/verification'
import { Methods as Methods53 } from './api/v1/clients/_client_id@number/statements'
import { Methods as Methods54 } from './api/v1/clients/_client_id@number/statements/details.csv'
import { Methods as Methods55 } from './api/v1/clients/_client_id@number/statements/next_billing'
import { Methods as Methods56 } from './api/v1/clients/_client_id@number/user_block_lists'
import { Methods as Methods57 } from './api/v1/clients/_client_id@number/users/_user_id@number'
import { Methods as Methods58 } from './api/v1/clients/_client_id@number/users/_user_id@number/badges'
import { Methods as Methods59 } from './api/v1/clients/_client_id@number/users/_user_id@number/emergency_contact'
import { Methods as Methods60 } from './api/v1/clients/_client_id@number/users/_user_id@number/graduates'
import { Methods as Methods61 } from './api/v1/clients/_client_id@number/users/_user_id@number/groups'
import { Methods as Methods62 } from './api/v1/clients/_client_id@number/users/_user_id@number/offerings'
import { Methods as Methods63 } from './api/v1/clients/_client_id@number/users/_user_id@number/reviews_from_client'
import { Methods as Methods64 } from './api/v1/clients/_client_id@number/users/_user_id@number/skills'
import { Methods as Methods65 } from './api/v1/companies/_company_id@number'
import { Methods as Methods66 } from './api/v1/companies/_company_id@number/base'
import { Methods as Methods67 } from './api/v1/companies/_company_id@number/clients'
import { Methods as Methods68 } from './api/v1/companies/_company_id@number/confirmed_billing_operations/_confirmed_billing_operation_id@number/detail.pdf'
import { Methods as Methods69 } from './api/v1/companies/_company_id@number/statements'
import { Methods as Methods70 } from './api/v1/companies/_company_id@number/statements/billing_adjustments.csv'
import { Methods as Methods71 } from './api/v1/companies/_company_id@number/statements/billing_transfer_commissions.csv'
import { Methods as Methods72 } from './api/v1/companies/_company_id@number/statements/client_statements.csv'
import { Methods as Methods73 } from './api/v1/companies/_company_id@number/statements/company_statements.csv'
import { Methods as Methods74 } from './api/v1/companies/_company_id@number/statements/details'
import { Methods as Methods75 } from './api/v1/companies/_company_id@number/statements/details/by_client'
import { Methods as Methods76 } from './api/v1/companies/_company_id@number/statements/details/of_client.csv'
import { Methods as Methods77 } from './api/v1/companies/_company_id@number/worker_list_storages'
import { Methods as Methods78 } from './api/v1/companies/_company_id@number/worker_list_storages/_worker_list_storage_id@number'
import { Methods as Methods79 } from './api/v1/master_company_occupations'
import { Methods as Methods80 } from './api/v1/notices'
import { Methods as Methods81 } from './api/v1/notices/_notice_id@number/read'
import { Methods as Methods82 } from './api/v1/occupations'
import { Methods as Methods83 } from './api/v1/occupations/_occupation_id@number/skills'
import { Methods as Methods84 } from './api/v1/sign_in'
import { Methods as Methods85 } from './api/v1/sign_in/confirm'
import { Methods as Methods86 } from './api/v1/sign_in/refresh'
import { Methods as Methods87 } from './api/v1/sign_out'
import { Methods as Methods88 } from './api/v1/tags'
import { Methods as Methods89 } from './api/v2/clients/_client_id@number/offerings/_offering_id@number/users/reviews'
import { Methods as Methods90 } from './api/v2/clients/_client_id@number/users'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/app/api/v1/account'
  const PATH1 = '/app/api/v1/account_setup_tokens'
  const PATH2 = '/app/api/v1/accounts/me'
  const PATH3 = '/app/api/v1/accounts/me/accessible_resources/clients'
  const PATH4 = '/app/api/v1/accounts/me/accessible_resources/companies'
  const PATH5 = '/app/api/v1/accounts/me/email_change_request'
  const PATH6 = '/app/api/v1/accounts/me/notification_counts'
  const PATH7 = '/app/api/v1/accounts/me/password'
  const PATH8 = '/app/api/v1/accounts/me/subscription/clients'
  const PATH9 = '/app/api/v1/clients'
  const PATH10 = '/attendance'
  const PATH11 = '/attendances/fix_payment_requests'
  const PATH12 = '/base'
  const PATH13 = '/chat_rooms'
  const PATH14 = '/messages'
  const PATH15 = '/read'
  const PATH16 = '/client_reviews'
  const PATH17 = '/client_reviews/evaluation'
  const PATH18 = '/company/np_buyer_alert'
  const PATH19 = '/confirmed_billing_operations'
  const PATH20 = '/detail.pdf'
  const PATH21 = '/feedbacks'
  const PATH22 = '/groups'
  const PATH23 = '/groups/shared_group_summary'
  const PATH24 = '/invitation'
  const PATH25 = '/offering/multidate_posting_operations'
  const PATH26 = '/check'
  const PATH27 = '/offerings'
  const PATH28 = '/close'
  const PATH29 = '/closed_reason'
  const PATH30 = '/multidate_posting_operations'
  const PATH31 = '/unpublished'
  const PATH32 = '/users'
  const PATH33 = '/reject_request'
  const PATH34 = '/users/block_status'
  const PATH35 = '/users/unreviewed'
  const PATH36 = '/users.csv'
  const PATH37 = '/offerings/calendar'
  const PATH38 = '/offerings/unreviewed'
  const PATH39 = '/offerings/wage'
  const PATH40 = '/offers'
  const PATH41 = '/archive'
  const PATH42 = '/validate'
  const PATH43 = '/offerings/validate'
  const PATH44 = '/offers/suggestion'
  const PATH45 = '/phone_number/verification'
  const PATH46 = '/statements'
  const PATH47 = '/statements/details.csv'
  const PATH48 = '/statements/next_billing'
  const PATH49 = '/user_block_lists'
  const PATH50 = '/badges'
  const PATH51 = '/emergency_contact'
  const PATH52 = '/graduates'
  const PATH53 = '/reviews_from_client'
  const PATH54 = '/skills'
  const PATH55 = '/app/api/v1/companies'
  const PATH56 = '/clients'
  const PATH57 = '/statements/billing_adjustments.csv'
  const PATH58 = '/statements/billing_transfer_commissions.csv'
  const PATH59 = '/statements/client_statements.csv'
  const PATH60 = '/statements/company_statements.csv'
  const PATH61 = '/statements/details'
  const PATH62 = '/statements/details/by_client'
  const PATH63 = '/statements/details/of_client.csv'
  const PATH64 = '/worker_list_storages'
  const PATH65 = '/app/api/v1/master_company_occupations'
  const PATH66 = '/app/api/v1/notices'
  const PATH67 = '/app/api/v1/occupations'
  const PATH68 = '/app/api/v1/sign_in'
  const PATH69 = '/app/api/v1/sign_in/confirm'
  const PATH70 = '/app/api/v1/sign_in/refresh'
  const PATH71 = '/app/api/v1/sign_out'
  const PATH72 = '/app/api/v1/tags'
  const PATH73 = '/app/api/v2/clients'
  const PATH74 = '/users/reviews'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    api: {
      v1: {
        account: {
          get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
          $get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH0}`
        },
        account_setup_tokens: {
          _setup_token: (val0: string) => {
            const prefix0 = `${PATH1}/${val0}`

            return {
              get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix0, GET, option).json(),
              $get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix0, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods2['patch']['reqBody'], headers?: Methods2['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods2['patch']['status']>(prefix, prefix0, PATCH, option).send(),
              $patch: (option: { body: Methods2['patch']['reqBody'], headers?: Methods2['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods2['patch']['status']>(prefix, prefix0, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix0}`
            }
          },
          post: (option: { body: Methods1['post']['reqBody'], headers?: Methods1['post']['reqHeaders'], config?: T }) =>
            fetch<void, BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).send(),
          $post: (option: { body: Methods1['post']['reqBody'], headers?: Methods1['post']['reqHeaders'], config?: T }) =>
            fetch<void, BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH1}`
        },
        accounts: {
          me: {
            accessible_resources: {
              clients: {
                get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).json(),
                $get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH3}`
              },
              companies: {
                get: (option?: { headers?: Methods5['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).json(),
                $get: (option?: { headers?: Methods5['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH4}`
              }
            },
            email_change_request: {
              post: (option: { body: Methods6['post']['reqBody'], headers?: Methods6['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['post']['status']>(prefix, PATH5, POST, option).send(),
              $post: (option: { body: Methods6['post']['reqBody'], headers?: Methods6['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['post']['status']>(prefix, PATH5, POST, option).send().then(r => r.body),
              patch: (option: { body: Methods6['patch']['reqBody'], headers?: Methods6['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['patch']['status']>(prefix, PATH5, PATCH, option).send(),
              $patch: (option: { body: Methods6['patch']['reqBody'], headers?: Methods6['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['patch']['status']>(prefix, PATH5, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH5}`
            },
            notification_counts: {
              get: (option?: { headers?: Methods7['get']['reqHeaders'], config?: T }) =>
                fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).json(),
              $get: (option?: { headers?: Methods7['get']['reqHeaders'], config?: T }) =>
                fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH6}`
            },
            password: {
              patch: (option: { body: Methods8['patch']['reqBody'], headers?: Methods8['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods8['patch']['status']>(prefix, PATH7, PATCH, option).send(),
              $patch: (option: { body: Methods8['patch']['reqBody'], headers?: Methods8['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods8['patch']['status']>(prefix, PATH7, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH7}`
            },
            subscription: {
              clients: {
                get: (option?: { headers?: Methods9['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).json(),
                $get: (option?: { headers?: Methods9['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
                put: (option: { body: Methods9['put']['reqBody'], headers?: Methods9['put']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods9['put']['status']>(prefix, PATH8, PUT, option).send(),
                $put: (option: { body: Methods9['put']['reqBody'], headers?: Methods9['put']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods9['put']['status']>(prefix, PATH8, PUT, option).send().then(r => r.body),
                $path: () => `${prefix}${PATH8}`
              }
            },
            get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
              fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).json(),
            $get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
              fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods3['patch']['reqBody'], headers?: Methods3['patch']['reqHeaders'], config?: T }) =>
              fetch<void, BasicHeaders, Methods3['patch']['status']>(prefix, PATH2, PATCH, option).send(),
            $patch: (option: { body: Methods3['patch']['reqBody'], headers?: Methods3['patch']['reqHeaders'], config?: T }) =>
              fetch<void, BasicHeaders, Methods3['patch']['status']>(prefix, PATH2, PATCH, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH2}`
          }
        },
        clients: {
          _client_id: (val1: number) => {
            const prefix1 = `${PATH9}/${val1}`

            return {
              attendance: {
                get: (option?: { headers?: Methods11['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, `${prefix1}${PATH10}`, GET, option).json(),
                $get: (option?: { headers?: Methods11['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, `${prefix1}${PATH10}`, GET, option).json().then(r => r.body),
                post: (option?: { headers?: Methods11['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, `${prefix1}${PATH10}`, POST, option).json(),
                $post: (option?: { headers?: Methods11['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, `${prefix1}${PATH10}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH10}`
              },
              attendances: {
                fix_payment_requests: {
                  _fix_payment_request_id: (val2: number) => {
                    const prefix2 = `${prefix1}${PATH11}/${val2}`

                    return {
                      patch: (option: { body: Methods13['patch']['reqBody'], headers?: Methods13['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods13['patch']['status']>(prefix, prefix2, PATCH, option).send(),
                      $patch: (option: { body: Methods13['patch']['reqBody'], headers?: Methods13['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods13['patch']['status']>(prefix, prefix2, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix2}`
                    }
                  },
                  get: (option: { query: Methods12['get']['query'], headers?: Methods12['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, `${prefix1}${PATH11}`, GET, option).json(),
                  $get: (option: { query: Methods12['get']['query'], headers?: Methods12['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, `${prefix1}${PATH11}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods12['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH11}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                }
              },
              base: {
                get: (option?: { headers?: Methods14['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, `${prefix1}${PATH12}`, GET, option).json(),
                $get: (option?: { headers?: Methods14['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, `${prefix1}${PATH12}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH12}`
              },
              chat_rooms: {
                _chat_room_uid: (val3: string) => {
                  const prefix3 = `${prefix1}${PATH13}/${val3}`

                  return {
                    messages: {
                      post: (option: { body: Methods16['post']['reqBody'], headers?: Methods16['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods16['post']['status']>(prefix, `${prefix3}${PATH14}`, POST, option).send(),
                      $post: (option: { body: Methods16['post']['reqBody'], headers?: Methods16['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods16['post']['status']>(prefix, `${prefix3}${PATH14}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix3}${PATH14}`
                    }
                  }
                },
                _uid: (val4: string) => {
                  const prefix4 = `${prefix1}${PATH13}/${val4}`

                  return {
                    read: {
                      post: (option?: { headers?: Methods18['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods18['post']['status']>(prefix, `${prefix4}${PATH15}`, POST, option).send(),
                      $post: (option?: { headers?: Methods18['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods18['post']['status']>(prefix, `${prefix4}${PATH15}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix4}${PATH15}`
                    },
                    get: (option?: { headers?: Methods17['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, prefix4, GET, option).json(),
                    $get: (option?: { headers?: Methods17['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}`
                  }
                },
                get: (option: { query: Methods15['get']['query'], headers?: Methods15['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, `${prefix1}${PATH13}`, GET, option).json(),
                $get: (option: { query: Methods15['get']['query'], headers?: Methods15['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, `${prefix1}${PATH13}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods15['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH13}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              client_reviews: {
                evaluation: {
                  get: (option?: { headers?: Methods20['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, `${prefix1}${PATH17}`, GET, option).json(),
                  $get: (option?: { headers?: Methods20['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, `${prefix1}${PATH17}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH17}`
                },
                get: (option: { query: Methods19['get']['query'], headers?: Methods19['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, `${prefix1}${PATH16}`, GET, option).json(),
                $get: (option: { query: Methods19['get']['query'], headers?: Methods19['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, `${prefix1}${PATH16}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods19['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH16}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              company: {
                np_buyer_alert: {
                  get: (option?: { headers?: Methods21['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, `${prefix1}${PATH18}`, GET, option).json(),
                  $get: (option?: { headers?: Methods21['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, `${prefix1}${PATH18}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH18}`
                }
              },
              confirmed_billing_operations: {
                _confirmed_billing_operation_id: (val5: number) => {
                  const prefix5 = `${prefix1}${PATH19}/${val5}`

                  return {
                    detail_pdf: {
                      get: (option?: { headers?: Methods22['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods22['get']['resHeaders'], Methods22['get']['status']>(prefix, `${prefix5}${PATH20}`, GET, option).send(),
                      $get: (option?: { headers?: Methods22['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods22['get']['resHeaders'], Methods22['get']['status']>(prefix, `${prefix5}${PATH20}`, GET, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH20}`
                    }
                  }
                }
              },
              feedbacks: {
                post: (option: { body: Methods23['post']['reqBody'], headers?: Methods23['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods23['post']['status']>(prefix, `${prefix1}${PATH21}`, POST, option).send(),
                $post: (option: { body: Methods23['post']['reqBody'], headers?: Methods23['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods23['post']['status']>(prefix, `${prefix1}${PATH21}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH21}`
              },
              groups: {
                _group_id: (val6: number) => {
                  const prefix6 = `${prefix1}${PATH22}/${val6}`

                  return {
                    get: (option?: { headers?: Methods25['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix6, GET, option).json(),
                    $get: (option?: { headers?: Methods25['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix6, GET, option).json().then(r => r.body),
                    patch: (option: { body: Methods25['patch']['reqBody'], headers?: Methods25['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods25['patch']['resBody'], BasicHeaders, Methods25['patch']['status']>(prefix, prefix6, PATCH, option).json(),
                    $patch: (option: { body: Methods25['patch']['reqBody'], headers?: Methods25['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods25['patch']['resBody'], BasicHeaders, Methods25['patch']['status']>(prefix, prefix6, PATCH, option).json().then(r => r.body),
                    delete: (option?: { query?: Methods25['delete']['query'], headers?: Methods25['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods25['delete']['status']>(prefix, prefix6, DELETE, option).send(),
                    $delete: (option?: { query?: Methods25['delete']['query'], headers?: Methods25['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods25['delete']['status']>(prefix, prefix6, DELETE, option).send().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods25['delete']['query'] }) =>
                      `${prefix}${prefix6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                shared_group_summary: {
                  get: (option?: { headers?: Methods26['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, `${prefix1}${PATH23}`, GET, option).json(),
                  $get: (option?: { headers?: Methods26['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, `${prefix1}${PATH23}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH23}`
                },
                get: (option?: { query?: Methods24['get']['query'], headers?: Methods24['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, `${prefix1}${PATH22}`, GET, option).json(),
                $get: (option?: { query?: Methods24['get']['query'], headers?: Methods24['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, `${prefix1}${PATH22}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods24['post']['reqBody'], headers?: Methods24['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods24['post']['resBody'], BasicHeaders, Methods24['post']['status']>(prefix, `${prefix1}${PATH22}`, POST, option).json(),
                $post: (option: { body: Methods24['post']['reqBody'], headers?: Methods24['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods24['post']['resBody'], BasicHeaders, Methods24['post']['status']>(prefix, `${prefix1}${PATH22}`, POST, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods24['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH22}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              invitation: {
                get: (option?: { headers?: Methods27['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, `${prefix1}${PATH24}`, GET, option).json(),
                $get: (option?: { headers?: Methods27['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, `${prefix1}${PATH24}`, GET, option).json().then(r => r.body),
                post: (option?: { headers?: Methods27['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods27['post']['resBody'], BasicHeaders, Methods27['post']['status']>(prefix, `${prefix1}${PATH24}`, POST, option).json(),
                $post: (option?: { headers?: Methods27['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods27['post']['resBody'], BasicHeaders, Methods27['post']['status']>(prefix, `${prefix1}${PATH24}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH24}`
              },
              offering: {
                multidate_posting_operations: {
                  _multidate_posting_operation_id: (val7: number) => {
                    const prefix7 = `${prefix1}${PATH25}/${val7}`

                    return {
                      check: {
                        patch: (option?: { headers?: Methods29['patch']['reqHeaders'], config?: T }) =>
                          fetch<Methods29['patch']['resBody'], BasicHeaders, Methods29['patch']['status']>(prefix, `${prefix7}${PATH26}`, PATCH, option).json(),
                        $patch: (option?: { headers?: Methods29['patch']['reqHeaders'], config?: T }) =>
                          fetch<Methods29['patch']['resBody'], BasicHeaders, Methods29['patch']['status']>(prefix, `${prefix7}${PATH26}`, PATCH, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix7}${PATH26}`
                      }
                    }
                  },
                  get: (option?: { headers?: Methods28['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods28['get']['resBody'], BasicHeaders, Methods28['get']['status']>(prefix, `${prefix1}${PATH25}`, GET, option).json(),
                  $get: (option?: { headers?: Methods28['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods28['get']['resBody'], BasicHeaders, Methods28['get']['status']>(prefix, `${prefix1}${PATH25}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH25}`
                }
              },
              offerings: {
                _offering_id: (val8: number) => {
                  const prefix8 = `${prefix1}${PATH27}/${val8}`

                  return {
                    close: {
                      patch: (option?: { headers?: Methods32['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods32['patch']['status']>(prefix, `${prefix8}${PATH28}`, PATCH, option).send(),
                      $patch: (option?: { headers?: Methods32['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods32['patch']['status']>(prefix, `${prefix8}${PATH28}`, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH28}`
                    },
                    closed_reason: {
                      post: (option: { body: Methods33['post']['reqBody'], headers?: Methods33['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods33['post']['status']>(prefix, `${prefix8}${PATH29}`, POST, option).send(),
                      $post: (option: { body: Methods33['post']['reqBody'], headers?: Methods33['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods33['post']['status']>(prefix, `${prefix8}${PATH29}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH29}`
                    },
                    multidate_posting_operations: {
                      post: (option: { body: Methods34['post']['reqBody'], headers?: Methods34['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods34['post']['status']>(prefix, `${prefix8}${PATH30}`, POST, option).send(),
                      $post: (option: { body: Methods34['post']['reqBody'], headers?: Methods34['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods34['post']['status']>(prefix, `${prefix8}${PATH30}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH30}`
                    },
                    unpublished: {
                      patch: (option?: { headers?: Methods35['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods35['patch']['status']>(prefix, `${prefix8}${PATH31}`, PATCH, option).send(),
                      $patch: (option?: { headers?: Methods35['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods35['patch']['status']>(prefix, `${prefix8}${PATH31}`, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH31}`
                    },
                    users: {
                      _user_id: (val9: number) => {
                        const prefix9 = `${prefix8}${PATH32}/${val9}`

                        return {
                          reject_request: {
                            post: (option: { body: Methods37['post']['reqBody'], headers?: Methods37['post']['reqHeaders'], config?: T }) =>
                              fetch<Methods37['post']['resBody'], BasicHeaders, Methods37['post']['status']>(prefix, `${prefix9}${PATH33}`, POST, option).json(),
                            $post: (option: { body: Methods37['post']['reqBody'], headers?: Methods37['post']['reqHeaders'], config?: T }) =>
                              fetch<Methods37['post']['resBody'], BasicHeaders, Methods37['post']['status']>(prefix, `${prefix9}${PATH33}`, POST, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix9}${PATH33}`
                          }
                        }
                      },
                      block_status: {
                        get: (option: { query: Methods38['get']['query'], headers?: Methods38['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, `${prefix8}${PATH34}`, GET, option).json(),
                        $get: (option: { query: Methods38['get']['query'], headers?: Methods38['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, `${prefix8}${PATH34}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods38['get']['query'] }) =>
                          `${prefix}${prefix8}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      unreviewed: {
                        get: (option?: { headers?: Methods39['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, `${prefix8}${PATH35}`, GET, option).json(),
                        $get: (option?: { headers?: Methods39['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, `${prefix8}${PATH35}`, GET, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix8}${PATH35}`
                      },
                      get: (option?: { headers?: Methods36['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, `${prefix8}${PATH32}`, GET, option).json(),
                      $get: (option?: { headers?: Methods36['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, `${prefix8}${PATH32}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH32}`
                    },
                    users_csv: {
                      get: (option?: { headers?: Methods40['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods40['get']['resHeaders'], Methods40['get']['status']>(prefix, `${prefix8}${PATH36}`, GET, option).send(),
                      $get: (option?: { headers?: Methods40['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods40['get']['resHeaders'], Methods40['get']['status']>(prefix, `${prefix8}${PATH36}`, GET, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH36}`
                    },
                    get: (option?: { headers?: Methods31['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods31['get']['resBody'], BasicHeaders, Methods31['get']['status']>(prefix, prefix8, GET, option).json(),
                    $get: (option?: { headers?: Methods31['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods31['get']['resBody'], BasicHeaders, Methods31['get']['status']>(prefix, prefix8, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix8}`
                  }
                },
                calendar: {
                  get: (option: { query: Methods41['get']['query'], headers?: Methods41['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix1}${PATH37}`, GET, option).json(),
                  $get: (option: { query: Methods41['get']['query'], headers?: Methods41['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix1}${PATH37}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods41['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH37}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                unreviewed: {
                  get: (option?: { headers?: Methods42['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, `${prefix1}${PATH38}`, GET, option).json(),
                  $get: (option?: { headers?: Methods42['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, `${prefix1}${PATH38}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH38}`
                },
                wage: {
                  get: (option: { query: Methods43['get']['query'], headers?: Methods43['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, `${prefix1}${PATH39}`, GET, option).json(),
                  $get: (option: { query: Methods43['get']['query'], headers?: Methods43['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, `${prefix1}${PATH39}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods43['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH39}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option: { query: Methods30['get']['query'], headers?: Methods30['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix1}${PATH27}`, GET, option).json(),
                $get: (option: { query: Methods30['get']['query'], headers?: Methods30['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix1}${PATH27}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods30['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH27}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              offers: {
                _offer_id: (val10: number) => {
                  const prefix10 = `${prefix1}${PATH40}/${val10}`

                  return {
                    archive: {
                      patch: (option?: { headers?: Methods46['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods46['patch']['status']>(prefix, `${prefix10}${PATH41}`, PATCH, option).send(),
                      $patch: (option?: { headers?: Methods46['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods46['patch']['status']>(prefix, `${prefix10}${PATH41}`, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix10}${PATH41}`
                    },
                    offerings: {
                      _offering_id: (val11: number) => {
                        const prefix11 = `${prefix10}${PATH27}/${val11}`

                        return {
                          validate: {
                            post: (option: { body: Methods49['post']['reqBody'], headers?: Methods49['post']['reqHeaders'], config?: T }) =>
                              fetch<void, BasicHeaders, Methods49['post']['status']>(prefix, `${prefix11}${PATH42}`, POST, option).send(),
                            $post: (option: { body: Methods49['post']['reqBody'], headers?: Methods49['post']['reqHeaders'], config?: T }) =>
                              fetch<void, BasicHeaders, Methods49['post']['status']>(prefix, `${prefix11}${PATH42}`, POST, option).send().then(r => r.body),
                            $path: () => `${prefix}${prefix11}${PATH42}`
                          },
                          patch: (option: { body: Methods48['patch']['reqBody'], headers?: Methods48['patch']['reqHeaders'], config?: T }) =>
                            fetch<void, BasicHeaders, Methods48['patch']['status']>(prefix, prefix11, PATCH, option).send(),
                          $patch: (option: { body: Methods48['patch']['reqBody'], headers?: Methods48['patch']['reqHeaders'], config?: T }) =>
                            fetch<void, BasicHeaders, Methods48['patch']['status']>(prefix, prefix11, PATCH, option).send().then(r => r.body),
                          $path: () => `${prefix}${prefix11}`
                        }
                      },
                      validate: {
                        post: (option: { body: Methods50['post']['reqBody'], headers?: Methods50['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods50['post']['status']>(prefix, `${prefix10}${PATH43}`, POST, option).send(),
                        $post: (option: { body: Methods50['post']['reqBody'], headers?: Methods50['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods50['post']['status']>(prefix, `${prefix10}${PATH43}`, POST, option).send().then(r => r.body),
                        $path: () => `${prefix}${prefix10}${PATH43}`
                      },
                      get: (option: { query: Methods47['get']['query'], headers?: Methods47['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods47['get']['resBody'], BasicHeaders, Methods47['get']['status']>(prefix, `${prefix10}${PATH27}`, GET, option).json(),
                      $get: (option: { query: Methods47['get']['query'], headers?: Methods47['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods47['get']['resBody'], BasicHeaders, Methods47['get']['status']>(prefix, `${prefix10}${PATH27}`, GET, option).json().then(r => r.body),
                      post: (option: { body: Methods47['post']['reqBody'], headers?: Methods47['post']['reqHeaders'], config?: T }) =>
                        fetch<Methods47['post']['resBody'], BasicHeaders, Methods47['post']['status']>(prefix, `${prefix10}${PATH27}`, POST, option).json(),
                      $post: (option: { body: Methods47['post']['reqBody'], headers?: Methods47['post']['reqHeaders'], config?: T }) =>
                        fetch<Methods47['post']['resBody'], BasicHeaders, Methods47['post']['status']>(prefix, `${prefix10}${PATH27}`, POST, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods47['get']['query'] }) =>
                        `${prefix}${prefix10}${PATH27}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { headers?: Methods45['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, prefix10, GET, option).json(),
                    $get: (option?: { headers?: Methods45['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, prefix10, GET, option).json().then(r => r.body),
                    patch: (option: { body: Methods45['patch']['reqBody'], headers?: Methods45['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods45['patch']['resBody'], BasicHeaders, Methods45['patch']['status']>(prefix, prefix10, PATCH, option).json(),
                    $patch: (option: { body: Methods45['patch']['reqBody'], headers?: Methods45['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods45['patch']['resBody'], BasicHeaders, Methods45['patch']['status']>(prefix, prefix10, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix10}`
                  }
                },
                suggestion: {
                  get: (option: { query: Methods51['get']['query'], headers?: Methods51['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods51['get']['resBody'], BasicHeaders, Methods51['get']['status']>(prefix, `${prefix1}${PATH44}`, GET, option).json(),
                  $get: (option: { query: Methods51['get']['query'], headers?: Methods51['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods51['get']['resBody'], BasicHeaders, Methods51['get']['status']>(prefix, `${prefix1}${PATH44}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods51['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH44}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option: { query: Methods44['get']['query'], headers?: Methods44['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, `${prefix1}${PATH40}`, GET, option).json(),
                $get: (option: { query: Methods44['get']['query'], headers?: Methods44['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, `${prefix1}${PATH40}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods44['post']['reqBody'], headers?: Methods44['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods44['post']['resBody'], BasicHeaders, Methods44['post']['status']>(prefix, `${prefix1}${PATH40}`, POST, option).json(),
                $post: (option: { body: Methods44['post']['reqBody'], headers?: Methods44['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods44['post']['resBody'], BasicHeaders, Methods44['post']['status']>(prefix, `${prefix1}${PATH40}`, POST, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods44['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH40}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              phone_number: {
                verification: {
                  post: (option: { body: Methods52['post']['reqBody'], headers?: Methods52['post']['reqHeaders'], config?: T }) =>
                    fetch<Methods52['post']['resBody'], BasicHeaders, Methods52['post']['status']>(prefix, `${prefix1}${PATH45}`, POST, option).json(),
                  $post: (option: { body: Methods52['post']['reqBody'], headers?: Methods52['post']['reqHeaders'], config?: T }) =>
                    fetch<Methods52['post']['resBody'], BasicHeaders, Methods52['post']['status']>(prefix, `${prefix1}${PATH45}`, POST, option).json().then(r => r.body),
                  patch: (option: { body: Methods52['patch']['reqBody'], headers?: Methods52['patch']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods52['patch']['status']>(prefix, `${prefix1}${PATH45}`, PATCH, option).send(),
                  $patch: (option: { body: Methods52['patch']['reqBody'], headers?: Methods52['patch']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods52['patch']['status']>(prefix, `${prefix1}${PATH45}`, PATCH, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH45}`
                }
              },
              statements: {
                details_csv: {
                  get: (option?: { query?: Methods54['get']['query'], headers?: Methods54['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods54['get']['resHeaders'], Methods54['get']['status']>(prefix, `${prefix1}${PATH47}`, GET, option).send(),
                  $get: (option?: { query?: Methods54['get']['query'], headers?: Methods54['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods54['get']['resHeaders'], Methods54['get']['status']>(prefix, `${prefix1}${PATH47}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods54['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH47}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                next_billing: {
                  get: (option?: { headers?: Methods55['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, `${prefix1}${PATH48}`, GET, option).json(),
                  $get: (option?: { headers?: Methods55['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, `${prefix1}${PATH48}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH48}`
                },
                get: (option: { query: Methods53['get']['query'], headers?: Methods53['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, `${prefix1}${PATH46}`, GET, option).json(),
                $get: (option: { query: Methods53['get']['query'], headers?: Methods53['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, `${prefix1}${PATH46}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods53['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH46}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              user_block_lists: {
                post: (option: { body: Methods56['post']['reqBody'], headers?: Methods56['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods56['post']['resBody'], BasicHeaders, Methods56['post']['status']>(prefix, `${prefix1}${PATH49}`, POST, option).json(),
                $post: (option: { body: Methods56['post']['reqBody'], headers?: Methods56['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods56['post']['resBody'], BasicHeaders, Methods56['post']['status']>(prefix, `${prefix1}${PATH49}`, POST, option).json().then(r => r.body),
                delete: (option: { query: Methods56['delete']['query'], headers?: Methods56['delete']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods56['delete']['status']>(prefix, `${prefix1}${PATH49}`, DELETE, option).send(),
                $delete: (option: { query: Methods56['delete']['query'], headers?: Methods56['delete']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods56['delete']['status']>(prefix, `${prefix1}${PATH49}`, DELETE, option).send().then(r => r.body),
                $path: (option?: { method: 'delete'; query: Methods56['delete']['query'] }) =>
                  `${prefix}${prefix1}${PATH49}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              users: {
                _user_id: (val12: number) => {
                  const prefix12 = `${prefix1}${PATH32}/${val12}`

                  return {
                    badges: {
                      get: (option?: { headers?: Methods58['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods58['get']['resBody'], BasicHeaders, Methods58['get']['status']>(prefix, `${prefix12}${PATH50}`, GET, option).json(),
                      $get: (option?: { headers?: Methods58['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods58['get']['resBody'], BasicHeaders, Methods58['get']['status']>(prefix, `${prefix12}${PATH50}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH50}`
                    },
                    emergency_contact: {
                      get: (option?: { headers?: Methods59['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, `${prefix12}${PATH51}`, GET, option).json(),
                      $get: (option?: { headers?: Methods59['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, `${prefix12}${PATH51}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH51}`
                    },
                    graduates: {
                      post: (option?: { headers?: Methods60['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods60['post']['status']>(prefix, `${prefix12}${PATH52}`, POST, option).send(),
                      $post: (option?: { headers?: Methods60['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods60['post']['status']>(prefix, `${prefix12}${PATH52}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH52}`
                    },
                    groups: {
                      put: (option: { body: Methods61['put']['reqBody'], headers?: Methods61['put']['reqHeaders'], config?: T }) =>
                        fetch<Methods61['put']['resBody'], BasicHeaders, Methods61['put']['status']>(prefix, `${prefix12}${PATH22}`, PUT, option).json(),
                      $put: (option: { body: Methods61['put']['reqBody'], headers?: Methods61['put']['reqHeaders'], config?: T }) =>
                        fetch<Methods61['put']['resBody'], BasicHeaders, Methods61['put']['status']>(prefix, `${prefix12}${PATH22}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH22}`
                    },
                    offerings: {
                      get: (option: { query: Methods62['get']['query'], headers?: Methods62['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, `${prefix12}${PATH27}`, GET, option).json(),
                      $get: (option: { query: Methods62['get']['query'], headers?: Methods62['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, `${prefix12}${PATH27}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods62['get']['query'] }) =>
                        `${prefix}${prefix12}${PATH27}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    reviews_from_client: {
                      get: (option: { query: Methods63['get']['query'], headers?: Methods63['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods63['get']['resBody'], BasicHeaders, Methods63['get']['status']>(prefix, `${prefix12}${PATH53}`, GET, option).json(),
                      $get: (option: { query: Methods63['get']['query'], headers?: Methods63['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods63['get']['resBody'], BasicHeaders, Methods63['get']['status']>(prefix, `${prefix12}${PATH53}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods63['get']['query'] }) =>
                        `${prefix}${prefix12}${PATH53}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    skills: {
                      get: (option?: { headers?: Methods64['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix12}${PATH54}`, GET, option).json(),
                      $get: (option?: { headers?: Methods64['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix12}${PATH54}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH54}`
                    },
                    get: (option?: { headers?: Methods57['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, prefix12, GET, option).json(),
                    $get: (option?: { headers?: Methods57['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, prefix12, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix12}`
                  }
                }
              },
              get: (option?: { headers?: Methods10['get']['reqHeaders'], config?: T }) =>
                fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, prefix1, GET, option).json(),
              $get: (option?: { headers?: Methods10['get']['reqHeaders'], config?: T }) =>
                fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods10['patch']['reqBody'], headers?: Methods10['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods10['patch']['resBody'], BasicHeaders, Methods10['patch']['status']>(prefix, prefix1, PATCH, option).json(),
              $patch: (option: { body: Methods10['patch']['reqBody'], headers?: Methods10['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods10['patch']['resBody'], BasicHeaders, Methods10['patch']['status']>(prefix, prefix1, PATCH, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix1}`
            }
          }
        },
        companies: {
          _company_id: (val13: number) => {
            const prefix13 = `${PATH55}/${val13}`

            return {
              base: {
                get: (option?: { headers?: Methods66['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, `${prefix13}${PATH12}`, GET, option).json(),
                $get: (option?: { headers?: Methods66['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, `${prefix13}${PATH12}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix13}${PATH12}`
              },
              clients: {
                get: (option?: { headers?: Methods67['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, `${prefix13}${PATH56}`, GET, option).json(),
                $get: (option?: { headers?: Methods67['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, `${prefix13}${PATH56}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix13}${PATH56}`
              },
              confirmed_billing_operations: {
                _confirmed_billing_operation_id: (val14: number) => {
                  const prefix14 = `${prefix13}${PATH19}/${val14}`

                  return {
                    detail_pdf: {
                      get: (option?: { headers?: Methods68['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods68['get']['resHeaders'], Methods68['get']['status']>(prefix, `${prefix14}${PATH20}`, GET, option).send(),
                      $get: (option?: { headers?: Methods68['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods68['get']['resHeaders'], Methods68['get']['status']>(prefix, `${prefix14}${PATH20}`, GET, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix14}${PATH20}`
                    }
                  }
                }
              },
              statements: {
                billing_adjustments_csv: {
                  get: (option?: { query?: Methods70['get']['query'], headers?: Methods70['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods70['get']['resHeaders'], Methods70['get']['status']>(prefix, `${prefix13}${PATH57}`, GET, option).send(),
                  $get: (option?: { query?: Methods70['get']['query'], headers?: Methods70['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods70['get']['resHeaders'], Methods70['get']['status']>(prefix, `${prefix13}${PATH57}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods70['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH57}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                billing_transfer_commissions_csv: {
                  get: (option?: { query?: Methods71['get']['query'], headers?: Methods71['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods71['get']['resHeaders'], Methods71['get']['status']>(prefix, `${prefix13}${PATH58}`, GET, option).send(),
                  $get: (option?: { query?: Methods71['get']['query'], headers?: Methods71['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods71['get']['resHeaders'], Methods71['get']['status']>(prefix, `${prefix13}${PATH58}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods71['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH58}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                client_statements_csv: {
                  get: (option?: { query?: Methods72['get']['query'], headers?: Methods72['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods72['get']['resHeaders'], Methods72['get']['status']>(prefix, `${prefix13}${PATH59}`, GET, option).send(),
                  $get: (option?: { query?: Methods72['get']['query'], headers?: Methods72['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods72['get']['resHeaders'], Methods72['get']['status']>(prefix, `${prefix13}${PATH59}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods72['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH59}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                company_statements_csv: {
                  get: (option?: { query?: Methods73['get']['query'], headers?: Methods73['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods73['get']['resHeaders'], Methods73['get']['status']>(prefix, `${prefix13}${PATH60}`, GET, option).send(),
                  $get: (option?: { query?: Methods73['get']['query'], headers?: Methods73['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods73['get']['resHeaders'], Methods73['get']['status']>(prefix, `${prefix13}${PATH60}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods73['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH60}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                details: {
                  by_client: {
                    get: (option: { query: Methods75['get']['query'], headers?: Methods75['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods75['get']['resBody'], BasicHeaders, Methods75['get']['status']>(prefix, `${prefix13}${PATH62}`, GET, option).json(),
                    $get: (option: { query: Methods75['get']['query'], headers?: Methods75['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods75['get']['resBody'], BasicHeaders, Methods75['get']['status']>(prefix, `${prefix13}${PATH62}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods75['get']['query'] }) =>
                      `${prefix}${prefix13}${PATH62}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  of_client_csv: {
                    get: (option?: { query?: Methods76['get']['query'], headers?: Methods76['get']['reqHeaders'], config?: T }) =>
                      fetch<void, Methods76['get']['resHeaders'], Methods76['get']['status']>(prefix, `${prefix13}${PATH63}`, GET, option).send(),
                    $get: (option?: { query?: Methods76['get']['query'], headers?: Methods76['get']['reqHeaders'], config?: T }) =>
                      fetch<void, Methods76['get']['resHeaders'], Methods76['get']['status']>(prefix, `${prefix13}${PATH63}`, GET, option).send().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods76['get']['query'] }) =>
                      `${prefix}${prefix13}${PATH63}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  get: (option?: { query?: Methods74['get']['query'], headers?: Methods74['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, `${prefix13}${PATH61}`, GET, option).json(),
                  $get: (option?: { query?: Methods74['get']['query'], headers?: Methods74['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, `${prefix13}${PATH61}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods74['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH61}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option: { query: Methods69['get']['query'], headers?: Methods69['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix13}${PATH46}`, GET, option).json(),
                $get: (option: { query: Methods69['get']['query'], headers?: Methods69['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix13}${PATH46}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods69['get']['query'] }) =>
                  `${prefix}${prefix13}${PATH46}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              worker_list_storages: {
                _worker_list_storage_id: (val15: number) => {
                  const prefix15 = `${prefix13}${PATH64}/${val15}`

                  return {
                    delete: (option?: { headers?: Methods78['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods78['delete']['status']>(prefix, prefix15, DELETE, option).send(),
                    $delete: (option?: { headers?: Methods78['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods78['delete']['status']>(prefix, prefix15, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix15}`
                  }
                },
                get: (option?: { headers?: Methods77['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods77['get']['resBody'], BasicHeaders, Methods77['get']['status']>(prefix, `${prefix13}${PATH64}`, GET, option).json(),
                $get: (option?: { headers?: Methods77['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods77['get']['resBody'], BasicHeaders, Methods77['get']['status']>(prefix, `${prefix13}${PATH64}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods77['post']['reqBody'], headers?: Methods77['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods77['post']['status']>(prefix, `${prefix13}${PATH64}`, POST, option).send(),
                $post: (option: { body: Methods77['post']['reqBody'], headers?: Methods77['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods77['post']['status']>(prefix, `${prefix13}${PATH64}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix13}${PATH64}`
              },
              get: (option?: { headers?: Methods65['get']['reqHeaders'], config?: T }) =>
                fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, prefix13, GET, option).json(),
              $get: (option?: { headers?: Methods65['get']['reqHeaders'], config?: T }) =>
                fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, prefix13, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods65['patch']['reqBody'], headers?: Methods65['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods65['patch']['resBody'], BasicHeaders, Methods65['patch']['status']>(prefix, prefix13, PATCH, option).json(),
              $patch: (option: { body: Methods65['patch']['reqBody'], headers?: Methods65['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods65['patch']['resBody'], BasicHeaders, Methods65['patch']['status']>(prefix, prefix13, PATCH, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix13}`
            }
          }
        },
        master_company_occupations: {
          get: (option?: { headers?: Methods79['get']['reqHeaders'], config?: T }) =>
            fetch<Methods79['get']['resBody'], BasicHeaders, Methods79['get']['status']>(prefix, PATH65, GET, option).json(),
          $get: (option?: { headers?: Methods79['get']['reqHeaders'], config?: T }) =>
            fetch<Methods79['get']['resBody'], BasicHeaders, Methods79['get']['status']>(prefix, PATH65, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH65}`
        },
        notices: {
          _notice_id: (val16: number) => {
            const prefix16 = `${PATH66}/${val16}`

            return {
              read: {
                post: (option?: { headers?: Methods81['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods81['post']['status']>(prefix, `${prefix16}${PATH15}`, POST, option).send(),
                $post: (option?: { headers?: Methods81['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods81['post']['status']>(prefix, `${prefix16}${PATH15}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix16}${PATH15}`
              }
            }
          },
          get: (option: { query: Methods80['get']['query'], headers?: Methods80['get']['reqHeaders'], config?: T }) =>
            fetch<Methods80['get']['resBody'], BasicHeaders, Methods80['get']['status']>(prefix, PATH66, GET, option).json(),
          $get: (option: { query: Methods80['get']['query'], headers?: Methods80['get']['reqHeaders'], config?: T }) =>
            fetch<Methods80['get']['resBody'], BasicHeaders, Methods80['get']['status']>(prefix, PATH66, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods80['get']['query'] }) =>
            `${prefix}${PATH66}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        occupations: {
          _occupation_id: (val17: number) => {
            const prefix17 = `${PATH67}/${val17}`

            return {
              skills: {
                get: (option?: { headers?: Methods83['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods83['get']['resBody'], BasicHeaders, Methods83['get']['status']>(prefix, `${prefix17}${PATH54}`, GET, option).json(),
                $get: (option?: { headers?: Methods83['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods83['get']['resBody'], BasicHeaders, Methods83['get']['status']>(prefix, `${prefix17}${PATH54}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix17}${PATH54}`
              }
            }
          },
          get: (option?: { headers?: Methods82['get']['reqHeaders'], config?: T }) =>
            fetch<Methods82['get']['resBody'], BasicHeaders, Methods82['get']['status']>(prefix, PATH67, GET, option).json(),
          $get: (option?: { headers?: Methods82['get']['reqHeaders'], config?: T }) =>
            fetch<Methods82['get']['resBody'], BasicHeaders, Methods82['get']['status']>(prefix, PATH67, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH67}`
        },
        sign_in: {
          confirm: {
            get: (option?: { headers?: Methods85['get']['reqHeaders'], config?: T }) =>
              fetch<Methods85['get']['resBody'], BasicHeaders, Methods85['get']['status']>(prefix, PATH69, GET, option).json(),
            $get: (option?: { headers?: Methods85['get']['reqHeaders'], config?: T }) =>
              fetch<Methods85['get']['resBody'], BasicHeaders, Methods85['get']['status']>(prefix, PATH69, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH69}`
          },
          refresh: {
            patch: (option?: { headers?: Methods86['patch']['reqHeaders'], config?: T }) =>
              fetch<void, Methods86['patch']['resHeaders'], Methods86['patch']['status']>(prefix, PATH70, PATCH, option).send(),
            $patch: (option?: { headers?: Methods86['patch']['reqHeaders'], config?: T }) =>
              fetch<void, Methods86['patch']['resHeaders'], Methods86['patch']['status']>(prefix, PATH70, PATCH, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH70}`
          },
          post: (option: { body: Methods84['post']['reqBody'], headers?: Methods84['post']['reqHeaders'], config?: T }) =>
            fetch<void, Methods84['post']['resHeaders'], Methods84['post']['status']>(prefix, PATH68, POST, option).send(),
          $post: (option: { body: Methods84['post']['reqBody'], headers?: Methods84['post']['reqHeaders'], config?: T }) =>
            fetch<void, Methods84['post']['resHeaders'], Methods84['post']['status']>(prefix, PATH68, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH68}`
        },
        sign_out: {
          delete: (option?: { headers?: Methods87['delete']['reqHeaders'], config?: T }) =>
            fetch<Methods87['delete']['resBody'], Methods87['delete']['resHeaders'], Methods87['delete']['status']>(prefix, PATH71, DELETE, option).json(),
          $delete: (option?: { headers?: Methods87['delete']['reqHeaders'], config?: T }) =>
            fetch<Methods87['delete']['resBody'], Methods87['delete']['resHeaders'], Methods87['delete']['status']>(prefix, PATH71, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH71}`
        },
        tags: {
          get: (option?: { headers?: Methods88['get']['reqHeaders'], config?: T }) =>
            fetch<Methods88['get']['resBody'], BasicHeaders, Methods88['get']['status']>(prefix, PATH72, GET, option).json(),
          $get: (option?: { headers?: Methods88['get']['reqHeaders'], config?: T }) =>
            fetch<Methods88['get']['resBody'], BasicHeaders, Methods88['get']['status']>(prefix, PATH72, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH72}`
        }
      },
      v2: {
        clients: {
          _client_id: (val18: number) => {
            const prefix18 = `${PATH73}/${val18}`

            return {
              offerings: {
                _offering_id: (val19: number) => {
                  const prefix19 = `${prefix18}${PATH27}/${val19}`

                  return {
                    users: {
                      reviews: {
                        post: (option: { body: Methods89['post']['reqBody'], headers?: Methods89['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods89['post']['status']>(prefix, `${prefix19}${PATH74}`, POST, option).send(),
                        $post: (option: { body: Methods89['post']['reqBody'], headers?: Methods89['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods89['post']['status']>(prefix, `${prefix19}${PATH74}`, POST, option).send().then(r => r.body),
                        $path: () => `${prefix}${prefix19}${PATH74}`
                      }
                    }
                  }
                }
              },
              users: {
                get: (option?: { query?: Methods90['get']['query'], headers?: Methods90['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods90['get']['resBody'], BasicHeaders, Methods90['get']['status']>(prefix, `${prefix18}${PATH32}`, GET, option).json(),
                $get: (option?: { query?: Methods90['get']['query'], headers?: Methods90['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods90['get']['resBody'], BasicHeaders, Methods90['get']['status']>(prefix, `${prefix18}${PATH32}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods90['get']['query'] }) =>
                  `${prefix}${prefix18}${PATH32}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              }
            }
          }
        }
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
